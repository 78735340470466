<template>
  <div class="common-bg-member">
    <div class="w700 common-padding-120">
      <h1>
        忘記密碼
      </h1>
      <div class="m-form-item">
        <p>電郵</p>
        <el-input v-model="email"  class="m-input" />
      </div>
      <div class="m-form-item">
        <p>驗證碼</p>
        <div style=" width: 100%;position: relative">
          <div class="yzm">
            發送驗證碼
          </div>
          <el-input v-model="yzm"  class="m-input" />
        </div>
      </div>

      <div class="m-form-item">
        <p>設置新密碼</p>
        <el-input  v-model="password" type="password"  class="m-input" />
      </div>

      <div class="m-form-item">
        <p>確認新密碼</p>
        <el-input  v-model="passwordMore" type="password"  class="m-input" />
      </div>

      <div class="m-btn regi">
        <button class="m-btn-common">確認</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Forget",
  data(){
    return{
      email:'',
      password:'',
      passwordMore:'',
      yam:''
    }
  }
}
</script>

<style scoped>

</style>